import { environment } from "../environments/environment"
export class AssetsPath {
    public static images = 'images%2F';
    public static brandingAssetsStudent = {
        logo: `${environment.assetBaseUrl}${AssetsPath.images}university-logo.png?alt=media`,
        favicon: `${environment.assetBaseUrl}${AssetsPath.images}favicon.ico?alt=media`,
        favicon_ico: `${environment.assetBaseUrl}${AssetsPath.images}favicon.ico?alt=media`,
        Chatbot:`${environment.assetBaseUrl}${AssetsPath.images}Chatbot.png?alt=media`,
        Charlotte:`${environment.assetBaseUrl}${AssetsPath.images}Charlotte.png?alt=media`
    };
}