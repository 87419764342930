import { Component, OnInit } from '@angular/core';
declare function setApiUrlsForEnrollment(a: any): any;
declare function getChatbotIdForEnrollmentBot(): any;
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent implements OnInit {

  isChecked:boolean = false;
  welcomeAcknowledgePage = true;
  acknowledgeDocument = false;
  userAcknowledgementStatus = false;
  public isVisible: boolean = false;
  public showDiv: boolean = true;
  public isChatbotOpen: boolean = false;
  public isMaximizeFromSalesforceUser: boolean = false;
  public isMaximized: boolean = false;
  selectedCategory: string = 'Walden General';

  constructor() { }

  ngOnInit() {
    setApiUrlsForEnrollment(environment.enrollmentConfig);
    this.toggleVisibility();
    getChatbotIdForEnrollmentBot();
    this.checkAcknowledge();
    window.addEventListener('message', this.receiveMessageFromSalesforce.bind(this), false);
  }

  checkAcknowledge() {
    var getResult = localStorage.getItem('salesforceuserack');
    if(getResult) {
      this.userAcknowledgementStatus = true;
      this.welcomeAcknowledgePage = false;
    } else {
      this.userAcknowledgementStatus = false;
    }
  }

  acknowledgeRead(){
    this.welcomeAcknowledgePage = false;
    this.acknowledgeDocument = true;
  }

  userAcknowledged(){
   // console.log('is checked: ', isChecked);
    this.acknowledgeDocument = false;
    this.userAcknowledgementStatus = true;
    localStorage.setItem('salesforceuserack', this.userAcknowledgementStatus.toString());
  }

  fadeInFadeOut() {
    this.showDiv = !this.showDiv;
  }

  maximizeChat() {
    // const chatPopup = document.querySelector('.hk-chatbot-popup');
    // if (chatPopup.classList.contains('maximized')) {
    //   chatPopup.classList.remove('maximized');
    // } else {
    //   chatPopup.classList.add('maximized');
    // }
    this.isMaximizeFromSalesforceUser = !this.isMaximizeFromSalesforceUser;
    
    if (this.isMaximizeFromSalesforceUser) {
      this.sendMsgToSalesforceForMaximize();
    } else {
      this.sendMsgSalesforceForGoBackToOriginal();
    }
  }

  public toggleVisibility() {
    console.log('toddle function');
    this.isVisible = !this.isVisible;
    //this.sendMessageToSalesforceForMinimizeChatbot();
}

public FAQs = [
  {category:"Walden General",query:"What are the scholarship options available?"},
  {category:"Walden General",query:"What are the different modes are available?"},
  {category:"Walden General",query:"When will the scholarship be available?"},
  {category:"Walden General",query:"Am I eligible for scholarship?"},
  {category:"Walden General",query:"Tuition & Fees details for Tempo learning?"},
  {category:"Nursing",query:"What are the Nursing course available?"},
  {category:"Nursing",query:"What ia Nursing?"}
]

public showChatFAQs=[];

applyFAQsSelection(event: Event, string: String){
  if (string == '') {
    // var applyFAQ = document.getElementById("applyFaq");
    // applyFAQ.style.backgroundColor = "#C4C4C4";
    // var selectElement = document.getElementById("categorySelect") as HTMLSelectElement;
    // var selectedOption = selectElement.options[selectElement.selectedIndex].value;
    // console.log("Selected options : ",selectedOption);
    const selectElement = event.target as HTMLSelectElement;
      const selectedOption = selectElement.value;
      console.log("Selected options : ", selectedOption);
    if(this.FAQs.length>0){
      this.showChatFAQs=[];
      this.FAQs.forEach((FAQ)=>{
        if(selectedOption===FAQ.category){
          this.showChatFAQs.push({'query':FAQ.query});
        }
      })
    }
    console.log("Response list: ",this.showChatFAQs);
  } else {
      const selectedOption = this.selectedCategory;
      console.log("Selected options : ", selectedOption);
    if(this.FAQs.length>0){
      this.showChatFAQs=[];
      this.FAQs.forEach((FAQ)=>{
        if(selectedOption===FAQ.category){
          this.showChatFAQs.push({'query':FAQ.query});
        }
      })
    }
    console.log("Response list: ",this.showChatFAQs);
  }
}

sendMessageToSalesforceForMinimizeChatbot() {
  const message = { function: 'CLOSE_CHATBOT' };
  console.log('enrollmentConfig.salesforceConfig.url: ', environment.enrollmentConfig.salesforceConfigUrl);
  window.parent.postMessage(message, environment.enrollmentConfig.salesforceConfigUrl);
}

receiveMessageFromSalesforce(event: MessageEvent) {
  if (event.origin !== environment.enrollmentConfig.salesforceConfigUrl) {
    return;
  }
  const data = event.data;
  console.log('Message from origin Angular:', event.origin);
 // sessionStorage.setItem('salesforceUser', data.user_data.email);
  //sessionStorage.setItem('salesforceUserName', data.user_data.email);
  sessionStorage.setItem('userName',data.user_data.last_name +", "+data.user_data.first_name);
  sessionStorage.setItem('userEmail',data.user_data.email);
}

sendMsgToSalesforceForMaximize() {
  this.isMaximized = !this.isMaximized;
  const message = { function: 'MAXIMIZE_CHATBOT_SIZE' };
  var getChatbotBody = document.getElementById('chatbot-body');
  getChatbotBody.style.height = 78 + 'vh';
  console.log('enrollmentConfig.salesforceConfig.url: ', environment.enrollmentConfig.salesforceConfigUrl);
  window.parent.postMessage(message, environment.enrollmentConfig.salesforceConfigUrl);
}

sendMsgSalesforceForGoBackToOriginal() {
  this.isMaximized = !this.isMaximized;
  var getChatbotBody = document.getElementById('chatbot-body');
  getChatbotBody.style.height = 70 + 'vh';
  const message = { function: 'ORIGINAL_CHATBOT_SIZE'};
  window.parent.postMessage(message, environment.enrollmentConfig.salesforceConfigUrl);
}

}
