import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentAvaamoBotService {

constructor(private http: HttpClient) { }

  getStaffAccessToken() {
    const tokenDetails = JSON.parse(localStorage.getItem('okta-token-storage'));
    let access_token = tokenDetails.accessToken.accessToken;
    let refresh_token = '';
    let id_token = tokenDetails.idToken.idToken;
    let user_name = sessionStorage.getItem('userName');
    console.log("user: ",user_name);
    user_name = user_name.replace(",","");
    let userName = user_name.split(" ");

    const body = {
      uuid: sessionStorage.getItem('uid') || 'null',
      email_user: sessionStorage.getItem('userEmail'),
      last_name: userName[0],
      first_name: userName[1],
      accessToken: access_token,
      idToken: id_token,
      refreshToken: sessionStorage.getItem('refresh_token'),
      dyn8x8_GTM: 'GTM-microapp'
    };
   return this.http.post(environment.studentAsStaffConfig.botApiUrl, body);
  }

}
