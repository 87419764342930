import { Component , HostListener} from '@angular/core';
import { AssetsPath } from 'src/static-assets/assets-path';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gcp',
  templateUrl: './gcp.component.html',
  styleUrls: ['./gcp.component.scss']
})
export class GcpComponent {

  public boxArea = "box1";
  public mainContainer = "main-container"
  public buttonArea = "btn-area";
  public showAcknowledge = false;
  public isDisabled = true;
  public disabledButton = false;
  public chatIconShow = false;
  public toShowGCP = false;
  public assetsPath = AssetsPath;
  public toshowTick = false;
  public title = `Chat bots for ${environment.name} University`;

  public clickAcknowledgment()
{
  this.isDisabled= !this.isDisabled;
}

public readAcknowledgment()
{
  this.showAcknowledge= !this.showAcknowledge;
}
public startConversation(){
  this.showAcknowledge= !this.showAcknowledge;
  this.boxArea = "box1 landing"
  this.buttonArea = "accepted_button";
  this.disabledButton = true;
  this.toshowTick = true;
  this.chatIconShow = true;
  this.mainContainer = "main-container opacity"
  setTimeout(() => {
    window.location.href = 'https://supportbot.genai.waldenu.edu/'
}, 3000);
  
}
public closeButton()
{
  this.showAcknowledge = !this.showAcknowledge;
}
}