declare function setApiUrlsForStudentStaff(a: any): any;
declare function getChatbotIdForStudentBot(): any;
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { IDToken, OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

import { AssetsPath } from 'src/static-assets/assets-path';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-azure',
  templateUrl: './azure.component.html',
  styleUrls: ['./azure.component.scss']
})
export class AzureComponent implements OnInit{


  public boxArea = "box1";
  public buttonArea = "btn-area";
  public showAcknowledge = false;
  public isDisabled = true;
  public disabledButton = false;
  public chatIconShow = false;
  public toShow = false;
  public assetsPath = AssetsPath;
  public toshowTick = false;
  public title = `Chat bots for ${environment.name} University`;
  public question1 = environment.Question1;
  public question2 = environment.Question2;
  public question3 = environment.Question3;
  public chatBotName = environment.chatbotName;
  public showDiv: boolean = true;
  isVisible: boolean = false;

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth){}

  

  public clickAcknowledgment()
{
  this.isDisabled= !this.isDisabled;
}

public readAcknowledgment()
{
  this.showAcknowledge= !this.showAcknowledge;
}
public startConversation(){
  this.showAcknowledge= !this.showAcknowledge;
  this.boxArea = "box1 landing"
  this.buttonArea = "accepted_button";
  this.disabledButton = true;
  this.toshowTick = true;
  this.chatIconShow = true;
}
public closeButton()
{
  this.showAcknowledge = !this.showAcknowledge;
}

toggleVisibility() {
  this.isVisible = !this.isVisible;
}

fadeInFadeOut() {
  this.showDiv = !this.showDiv;
}

maximizeChat() {
    const chatPopup = document.querySelector('.hk-chatbot-popup');
    if (chatPopup.classList.contains('maximized')) {
      chatPopup.classList.remove('maximized');
  } else {
      chatPopup.classList.add('maximized');
  }
  
}

 ngOnInit() {
  setApiUrlsForStudentStaff(environment.studentAsStaffConfig);
  getChatbotIdForStudentBot();
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
  sessionStorage.setItem('access_token',oktaToken.accessToken);
  this.getUserDetails();
}

async getUserDetails() {
  const user = await this.oktaAuth.getUser();
  sessionStorage.setItem('userEmail',user.email)
}

}