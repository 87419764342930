<div class="landing_main_wraper fade-in-down" style="text-align:center">
    <div class="main-container">
        <div class="logo_section">
            <img src="{{assetsPath.brandingAssetsStudent.logo}}" alt="Logo">
        </div>
        <div class="justify_space_between" *ngIf="title">
            <div class="landing_content_area">
				<div class="parentbox">
                <div [ngClass]="[boxArea]">
                    <h6>Student Bot</h6>
                    <h5>Goals</h5>
                    <p> The goal of this pilot is to implement and validate responses generated by chatbot for students trained using selected data sources with Generative AI technology and learn the following.</p>
                    <ul>
                        <li>Provide accurate, complete, and simple answers to the full range of common student questions with minimal training  </li>
                        <li>Provide students the information they need without having to speak to an agent, thus reducing top volume call drivers  </li>
                    </ul>
                    <h5>Nature of Information</h5>
					<div disabled class="textarea-placeholder">
						<div class="text-content">
                            The responses generated by this chatbot using generative AI are based on vast amounts of data and algorithms, but they may not always be correct, complete, or up-to-date. Users should treat the information as a starting point and verify from trusted sources before making decisions based on it.  For more information concerning the use of this chatbot please click the link to Adtalem’s Chatbot Use Acknowledgement Document.
							</div>
					</div>
					<div [class.isDisabled]="disabledButton" [ngClass]="[buttonArea]"  (click)="readAcknowledgment()"><div class="child1" >Please read and acknowledge document</div><div *ngIf="toshowTick" class="child2"><div  id="checkmark"></div></div></div>
					<div *ngIf ="showAcknowledge" class="acknowledge_container">
						<div class="acknowledge_heading">
							Acknowledge document
                            <span (click)="closeButton()" class="close"></span>
						</div>
						<div class="acknowledge_content_window">
							<div class="acknowledge_content">
								<div class="acknowledge_content_style">
								<br>
								<br>
								<br>
								<p>Acknowledge document</p> 
								<ol>
									<li>Nature of Information: The responses generated by this chatbot using generative AI 
										are based on vast amounts of data and algorithms, but they may not always be 
										correct, complete, or up-to-date. Users should treat the information as a starting 
										point and verify from trusted sources before making decisions based on it.</li>
									<li>No Human Oversight: The responses are generated automatically, without human 
										oversight or intervention. As such, they may occasionally contain errors, 
										misinterpretations, or irrelevant information. </li>
									<li> No Personal Advice: The information provided by the chatbot is general in nature 
										and should not be considered as personal advice. Users should consult with relevant 
										professionals before making decisions, especially in areas that require specialized 
										knowledge or expertise. </li>
									<li>Data Privacy: While this chatbot is designed to prioritize user privacy, users are 
										advised not to share sensitive, personal, or confidential information. We do not
										guarantee complete confidentiality or security.</li>
									<li>Limitation of Liability: The developers, operators, and affiliates of this chatbot will 
										not be liable for any direct, indirect, incidental, consequential, or special damages, 
										including but not limited to damages for loss of profits, goodwill, or data, arising out 
										of or in connection with the use of this chatbot. </li>
									<li>Not a Substitute: This chatbot is not a substitute for human expertise or judgment. 
										Relying solely on its responses without external validation can lead to misinformed 
										decisions.</li>
								</ol>
								</div>
							</div>
						</div>
						<div class="checkbox">
							<input type="checkbox" id ="chkbox" (click)="clickAcknowledgment()" [(ngModel)]="toShow" >&nbsp;I acknowledge and agree to the terms and conditions acknowledgment document.
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<button [disabled]="isDisabled" class="conversation_btn"  (click)="startConversation()" >Start a conversation</button>
						</div>
						</div>
					</div>
				</div>
            </div>
        </div>
    </div>  
</div>

<div *ngIf="chatIconShow" class="hk-wrapper" data-layout="twocolumn" data-menu="light" data-footer="simple" data-hover="active"
		data-submenu="active">

		<!-- Chat Popup -->
		<div class="hk-chatbot-popup d-md-block d-flex" *ngIf="isVisible" [ngClass]="{'fade-in': showDiv, 'fade-out': !showDiv}">
			<header>
				<div class="chatbot-head-top">
					<!-- <span class="text-white">{{chatBotName}}</span> -->
					<div class="media-head round">
						<div
							class="avatar avatar-sm avatar-soft-primary avatar-icon avatar-rounded position-relative">
							<div class="avatar avatar-sm avatar-rounded">
								<div class="header-img">
									<img src="https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2Fimages%2FChatbot.svg?alt=media" alt="user" class="avatar-img">
								</div>
								<!-- <img src="{{assetsPath.brandingAssetsStudent.Charlotte}}" alt="user" class="avatar-img"> -->
							</div>
							<span
								class="badge badge-success badge-indicator badge-indicator-lg badge-indicator-nobdr position-bottom-end-overflow-1" style="margin-bottom: 6px;"></span>
						</div>
					</div>
					<span class="text-white">{{chatBotName}}</span>
					<div style="display: flex;">
						<a id="fade_out" href="javascript:void(0);" class="btn btn-sm btn-icon btn-dark btn-rounded" (click)="fadeInFadeOut()">
							<span class="icon"><span class="feather-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
									<path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z"/>
									<path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z"/>
								  </svg>
							</span></span>
						</a>
						<a id="refresh_chat" class="btn btn-sm btn-icon btn-dark btn-rounded">
							<span class="icon"><span class="feather-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-ccw"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
							</span></span>
						</a>
						<a id="maximize_chatbot" href="javascript:void(0);" class="btn btn-sm btn-icon btn-dark btn-rounded maximize-button" (click)="maximizeChat()">
							<span class="icon"><span class="feather-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize">
									<path d="M15 3h6v6M21 9V3m-4 18h6v-6M9 21v4m-4-8H3m4 8V15"></path>
								</svg>
							</span></span>
						</a>
						<a  id="minimize_chatbot" href="javascript:void(0);"
							class="btn btn-sm btn-icon btn-dark btn-rounded minimize-button" (click)="toggleVisibility()">
							<span class="icon"><span class="feather-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12"></line>
								</svg>
							</span></span>
						</a>
					</div>
				</div>
				<!-- <div class="separator-full separator-light mt-0 opacity-10"></div> -->
				<div class="media-wrap">
					<div class="media">
						<!-- <div class="media-body">
							<div class="user-name">{{chatBotName}}</div>
							<div class="user-status">Active</div>
						</div> -->
					</div>
				</div>
			</header>
			<div class="chatbot-popup-body">
				<div data-simplebar class="nicescroll-bar">
					<div>
						<div class="init-content-wrap">
							<div class="card card-shadow">
								<div class="card-body">
									<p class="card-text">Hello I can help with any question regarding enrollment or
										academic programs.<br><br>Select the topic or start chatting.</p>
									<button class="btn btn-block btn-primary text-nonecase start-conversation btn-conversation">Start a
										conversation</button>
								</div>
							</div>
							<div class="btn-wrap">
								<button id="starting_ques_1"
									class="btn btn-soft-primary text-nonecase btn-rounded start-conversation"><span><span
											class="btn-text">{{question1}}</span></span></button>
								<button id="starting_ques_2"
									class="btn btn-soft-primary text-nonecase btn-rounded start-conversation"><span><span
											class="btn-text">{{question2}}
										</span></span></button>
								<button id="starting_ques_3"
									class="btn btn-soft-primary text-nonecase btn-rounded start-conversation"><span><span
											class="btn-text">{{question3}}</span></span></button>
							</div>
						</div>
						<ul class="list-unstyled d-none">
							<li class="media received">
								<div class="avatar avatar-xs avatar-soft-primary avatar-icon avatar-rounded">
									<div class="avatar avatar-xs avatar-rounded">
										<!-- <img src="{{assetsPath.brandingAssetsStudent.Chatbot}}" alt="user" class="avatar-img"> -->
										<img src="https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2Fimages%2FChatbot.svg?alt=media" alt="user" class="avatar-img">
									</div>
								</div>
								<div class="media-body">
									<div class="msg-box">
										<div>
											<p>Hello! How can I assist you today?</p>
										</div>
									</div>

								</div>
							</li>

						</ul>
					</div>
				</div>
			</div>
			<footer>
				<div class="input-group d-none">
					<input type="text" id="input_msg_chat_popup" name="send-msg"
						class="input-msg-send form-control border-0 shadow-none" placeholder="Ask me anything...">
				</div>
			</footer>
		</div>
		<a href="javascript:void(0);" class="btn-popup-open" (click)="toggleVisibility()">
			<div class="avatar avatar-sm avatar-icon avatar-rounded position-relative">
				<!-- <img src="{{assetsPath.brandingAssetsStudent.Chatbot}}" alt="user" class="avatar-img"> -->
				<img src="https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2Fimages%2FChatbot.svg?alt=media" alt="user" class="avatar-img">
			</div>
		</a>
		<!-- /Chat Popup -->
	</div>
