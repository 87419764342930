import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AvaamoComponent } from './components/student-support/avaamo/avaamo.component';
import { EnrollmentAgentComponent } from './components/enrollment-agent/enrollment-agent.component';
import { GcpComponent } from './components/student-support/gcp/gcp.component';
import { AzureComponent } from './components/student-support/azure/azure.component';
import {  OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
const routes: Routes = [
  // {path:'', redirectTo:'',pathMatch:'full'},
  {path:'login/callback',component:OktaCallbackComponent},
  {path:'studentbot',component:AvaamoComponent,  canActivate: [OktaAuthGuard],title: 'Student support-avaamo'},
  {path:'student-support-gcp', component:GcpComponent, canActivate: [OktaAuthGuard], title: 'Student support-gcp'},
  {path:'student-support-azure', component:AzureComponent, canActivate: [OktaAuthGuard], title:'Student support-azure'},
  {path:'enrollmentcopilot', component:EnrollmentAgentComponent,  canActivate: [OktaAuthGuard], title: 'Enrollment Co-pilot'},
  {path: 'chatbot', component: ChatbotComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
