<div class="landing_main_wraper fade-in-down" style="text-align:center">
    <div [ngClass]="[mainContainer]">
        <div class="logo_section">
            <!-- <img src="{{assetsPath.brandingAssetsStudent.logo}}" alt="Logo"> -->
        </div>
        <div class="justify_space_between" *ngIf="title">
            <div class="landing_content_area">
				<div class="parentbox">
                <div [ngClass]="[boxArea]">
                    <h6>Staff Assistant Bot</h6>
                    <h5>Goals</h5>
                    <p> The goal of this pilot is to implement and validate chatbot Student Support Team trained using selected data sources with Generative AI technology and learn following.</p>
                    <ul>
                        <li>Provide accurate, complete, and simple answers to the full range of common student questions with minimal training </li>
                        <li>Provide students the information they need without having to speak to an agent, thus reducing top volume call drivers </li>
                        <li>Help SSA and CCT with information from existing training and university resources to better serve students</li>
                    </ul>
                    <h5>Nature of Information</h5>
					<div disabled class="textarea-placeholder">
						<div class="text-content">
                            The responses generated by this chatbot using generative AI are based on vast amounts of data and algorithms, but they may not always be correct, complete, or up-to-date. Users should treat the information as a starting point and verify from trusted sources before making decisions based on it.  For more information concerning the use of this chatbot please click the link to Adtalem’s Chatbot Use Acknowledgement Document.
							</div>
					</div>
					<div [class.isDisabled]="disabledButton" [ngClass]="[buttonArea]"  (click)="readAcknowledgment()"><div class="child1" >Please read and acknowledge document</div><div *ngIf="toshowTick" class="child2"><div  id="checkmark"></div></div></div>
					<div *ngIf ="showAcknowledge" class="acknowledge_container">
						<div class="acknowledge_heading">
							Acknowledge document
                            <span (click)="closeButton()" class="close"></span>
						</div>
						<div class="acknowledge_content_window">
							<div class="acknowledge_content">
								<div class="acknowledge_content_style">
								<br>
								<br>
								<br>
								<p>Acknowledge document</p> 
								<ol>
									<li>Nature of Information: The responses generated by this chatbot using generative AI 
										are based on vast amounts of data and algorithms, but they may not always be 
										correct, complete, or up-to-date. Users should treat the information as a starting 
										point and verify from trusted sources before making decisions based on it.</li>
									<li>No Human Oversight: The responses are generated automatically, without human 
										oversight or intervention. As such, they may occasionally contain errors, 
										misinterpretations, or irrelevant information. </li>
									<li> No Personal Advice: The information provided by the chatbot is general in nature 
										and should not be considered as personal advice. Users should consult with relevant 
										professionals before making decisions, especially in areas that require specialized 
										knowledge or expertise. </li>
									<li>Data Privacy: While this chatbot is designed to prioritize user privacy, users are 
										advised not to share sensitive, personal, or confidential information. We do not
										guarantee complete confidentiality or security.</li>
									<li>Limitation of Liability: The developers, operators, and affiliates of this chatbot will 
										not be liable for any direct, indirect, incidental, consequential, or special damages, 
										including but not limited to damages for loss of profits, goodwill, or data, arising out 
										of or in connection with the use of this chatbot. </li>
									<li>Not a Substitute: This chatbot is not a substitute for human expertise or judgment. 
										Relying solely on its responses without external validation can lead to misinformed 
										decisions.</li>
								</ol>
								</div>
							</div>
						</div>
						<div class="checkbox">
							<input type="checkbox" id ="chkbox" (click)="clickAcknowledgment()" [(ngModel)]="toShow" >&nbsp;I acknowledge and agree to the terms and conditions acknowledgment document.
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<button [disabled]="isDisabled" class="conversation_btn"  (click)="startConversation()" >Start a conversation</button>
						</div>
						</div>
					</div>
				</div>
            </div>
        </div>
    </div>  
</div>
<!-- <div *ngIf="chatIconShow" [class.disabled] ="!toShowGCP">
    <df-messenger   
        project-id="walden-labs-prod-277621"
        agent-id="3fa55fc5-a491-417d-858c-3e23ea45025d"
        language-code="en">
        <df-messenger-chat-bubble 
        chat-title="GCP">
        </df-messenger-chat-bubble>
    </df-messenger>
</div> -->

<div *ngIf="chatIconShow" class="loader"></div>