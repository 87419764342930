import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AssetsPath } from 'src/static-assets/assets-path';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
  const faviconUrl = AssetsPath.brandingAssetsStudent.favicon;
  const faviconUrl_ico = AssetsPath.brandingAssetsStudent.favicon_ico;
 sessionStorage.setItem('faviconUrl', faviconUrl);